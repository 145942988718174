<script>
import Layout from "../../layouts/main";
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {

  components: {
    Layout,
  },
  data() {
    return {
      app_URL: process.env.VUE_APP_URL,
      familyData: [],
    }
  },
  created() {
    this.getFamilyMembers();
  },
  activated() {
    this.getFamilyMembers();
  },
  methods: {
    getFamilyMembers() {
      axios.post(this.app_URL + "api/getFamilyMember", { id: this.decode(this.$route.params.id) }).then((res) => {
        this.familyData = res.data.data;
      })
    },
    encode(id) {
      return encodeURIComponent(
        CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
      );
    },
    decode(id) {
      return decodeURIComponent(
        CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
          CryptoJS.enc.Utf8
        )
      );
    }
  },
}

</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">All Applicant List</h2>
        </div>
      </div>
      <div class="row gx-3" v-if="familyData.length > 0">
        <div class="col-lg-4" v-for="(item, index) in familyData" :key="index">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center p-2">
                <div class="col">
                  <!-- <div class="col"> -->
                    <span v-if="item.formStatus == 'progress'" class="text-primary pb-2 d-block">Progress</span>
                    <span v-else-if="item.paymentStatus != 'pending'" class="text-success pb-2 d-block">Completed</span>
                    <span v-else class="text-danger pb-2 d-block">${{ item.total }}</span>
                  <!-- </div> -->
                  <!-- <span v-if="item.formStatus == 'complete'" class="text-success pb-2 d-block">Completed</span>
                  <span v-if="item.formStatus == 'progress'" class="text-primary pb-2 d-block">Progress</span> -->
                  <p class="m-0 text-theme">{{ item.familyRelationship }}</p>
                  <h2 class="font-size-16 text-start m-0">{{ item.firstName }} {{ item.lastName }}</h2>
                </div>
                <div class="col-auto">
                  <router-link :to="`/single_applicant/${this.encode(item.id)}`" @click="$store.state.applicantCstep = 0"
                    class="btn btn-theme btn-sm"><i class="mdi mdi-eye fs-5"></i></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-center">No Data Yet</p>
      </div>
    </main>
  </Layout>
</template>